import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/handbook/post-layout/post-layout-parent.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`**`}</p>
    <h2 {...{
      "id": "a-what-do-i-need-to-know-about-student-parties-and-celebrations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#a-what-do-i-need-to-know-about-student-parties-and-celebrations",
        "aria-label": "a what do i need to know about student parties and celebrations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`A. What Do I Need To Know About Student Parties And Celebrations?`}</h2>
    <h4 {...{
      "id": "birthday-celebrations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#birthday-celebrations",
        "aria-label": "birthday celebrations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Birthday Celebrations`}</h4>
    <p>{`In consideration of children’s feelings, we ask that printed or electronic invitations be sent to school ONLY if all the children in the class / grade (or all the boys / girls) are invited. If you are unable to accommodate large numbers for parties, please contact families privately via email or phone.`}</p>
    <p>{`If you would like the class to share in a simple, easy-to-serve birthday cake (or cupcakes) for your child, please speak to the class teacher `}<em parentName="p">{` at least three days prior `}</em>{` to make an arrangement for the most suitable time on the lesson timetable. We aim to keep this process as simple and unobtrusive to the learning programme as possible.`}</p>
    <p>{`Please do not supply fizzy soft drinks, lollies, gift / lollie bags, presents and additional food as part of these birthday celebrations at the school. Invitations are not required and will not be distributed by the teachers.`}</p>
    <p><strong parentName="p">{`Changes to the Regular Leaving Routine: Birthday Parties, Play Dates and other Special Arrangements`}</strong></p>
    <p>{`Birthday parties are managed by parents. It is the responsibility of parents to inform the school about any changes in pick up or bus routines at the end of the day.`}</p>
    <p>{`Responsibilities:`}</p>
    <p>{`The Parent/s organising the party:`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Inviting Only Select Students`}</em></strong></p>
    <p>{`Please be sensitive to the emotions of children who might not be invited to the party. If the entire class or grade has not been invited, `}<em parentName="p">{` please make arrangements not connected to the school `}</em>{`.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Inviting the Whole Class`}</em></strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The homeroom teacher will be happy to forward the invitation for you only if you are inviting the whole class.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Parents remind invited families to inform the school about who will be collecting their children on the day of the party.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Parents supply the school with the list of who is attending the party so it can be double checked with security. If this list is provided in advance, Reception can make sure all parents on the list have given permission for children to leave with you. This will make the departure from school smooth and efficient.`}</p>
      </li>
    </ul>
    <p>{`The parents of invited students:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`If your child rides the bus, email Liliya Gotvyanska, our Bus Coordinator at `}<a parentName="p" {...{
            "href": "mailto:bus@psi.kiev.ua"
          }}>{` bus@psi.kiev.ua `}</a>{` to inform her that on the day of the party, your child will go home with the family organising the party. Send a copy of this email to Reception and the homeroom teacher.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If your child is picked up from school, email the homeroom teacher and inform them that on the day of the party, your child will go home with the family organising the party. Send a copy of this email to Reception.`}</p>
      </li>
    </ul>
    <p>{`The school will:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Forward any emails regarding the party to reception and / or the organising family `}<em parentName="p">{` when whole classes or grades are invited `}</em>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Provide Security with the list of students with confirmed parent permission to leave with the family for the party.`}</p>
      </li>
    </ul>
    <h4 {...{
      "id": "assemblies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#assemblies",
        "aria-label": "assemblies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Assemblies`}</h4>
    <p>{`Primary school assemblies are scheduled monthly and all grade levels, EC3 - Grade 5 attend.  Assemblies are held to give students opportunities to present their work, share what they have learnt, practice presentation skills and learn proper audience behaviour.  Assemblies take place in the auditorium or on Zoom as required and parents are welcome to attend.`}</p>
    <p><em parentName="p">{`The Grade 5 Moving Up Day is an important celebration at PSI.`}</em></p>
    <h2 {...{
      "id": "b-what-awards-can-students-earn-in-primary",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#b-what-awards-can-students-earn-in-primary",
        "aria-label": "b what awards can students earn in primary permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`B. What Awards Can Students Earn In Primary?`}</h2>
    <p><strong parentName="p">{`Abby Hulka Award`}</strong></p>
    <p>{`Presented to a Grade 5 student who demonstrates the positive qualities exhibited by Abby Hulka, a fifth grade student at PSI who tragically died in a car accident in October 2001. Abby was known for her friendliness, compassion, fairness, willingness to share, excellent academic work, and a positive attitude towards others.`}</p>
    <p><strong parentName="p">{`Sportsmanship Award`}</strong></p>
    <p>{`Presented for ability and a sense of fairness in athletics to a student in Grades 2 - 5.`}</p>
    <p><strong parentName="p">{`Sharon Ingerson Award`}</strong></p>
    <p>{`Awarded to the EC3 - Grade 4 student who best exhibits action related to learning and the world. Sharon Ingerson was a long-serving Primary principal at PSI.`}</p>
    <p>{`The winner is chosen on the following criteria:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`There is a direct connection between what is studied and the action taken by the student.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The action is self-generated and possibly not related to any class or summative work.`}</p>
      </li>
    </ol>
    <p>{`Students are nominated by teachers. A committee of volunteers reviews the nominations and makes a decision by 1 June.`}</p>
    <p>{`The awards are presented at a whole school assembly on the last day of the school year. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      